export const M = 'm';
export const F = 'f';
export const X = 'x';

export const getSexTypeTitle = (type: string) => {
  switch (type) {
    case M: return 'm';
    case F: return 'f';
    case X: return 'x';
  }
};

export const getSexTypeList = () => {
  return [
    { text: getSexTypeTitle(M), value: M },
    { text: getSexTypeTitle(F), value: F },
    { text: getSexTypeTitle(X), value: X },
  ];
};
