import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BPhoneInputValidation from '@/components/ui/inputs/b-phone-input-validation/bPhoneInputValidation.vue';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import { UserType } from '@/types/api/user/user.type';
import { UsersUpdatePermissionsRequest, UsersUpdateRequest } from '@/types/request/users-request.types';
import { getSexTypeList } from '@/constants/sex-type';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import UpdateUserPermissions from '@/components/ui/modals/updateUserPermissions.vue';
import { hasPermission, PERM_CAN_EDIT_PERMISSIONS, preparePermissionList } from '@/helpers/permission.helper';
import { Action, Getter } from 'vuex-class';
import { UserCard } from '@/store/modules/users/actions';
import { PermissionListType } from '@/types/api/user/permission-list.type';

const getTitle = () => window.location.href.includes('/user/update') ? 'users.update' : 'users.add';

@Component({
  name: 'UserForm',
  page: {
    title: getTitle()
  },
  components: {
    ValidationObserver,
    BTextInputValidation,
    BPhoneInputValidation,
    DatepickerSingle,
    BSelectInputValidation,
    VisibleIcon,
    PageTitle,
  },
})
export default class UserForm extends Vue {
  title = getTitle();
  tryingToUpdate = false;
  firstName = '';
  lastName = '';
  sex = '';
  email = '';
  birthday: string | null = null;
  passport = '';
  passportInternational = '';
  phoneNumber = '';

  @Prop({ required: false })
  user?: UserType;

  @Prop({ required: true })
  updateCallback!: (params: UsersUpdateRequest) => Promise<UserType>;

  @Getter('getCurrentUser', { namespace: 'auth' })
  currentUser!: UserType;

  @Action('updatePermissions', { namespace: 'users' })
  updatePermissions!: (data: UserCard<UsersUpdatePermissionsRequest>) => Promise<PermissionListType>;

  created(): void {
    if (this.user) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.sex = this.user.sex;
      this.email = this.user.socialEmail;
      this.birthday = this.user.birthday;
      this.passport = this.user.passport;
      this.passportInternational = this.user.passportInternational;
      this.phoneNumber = this.user.phoneNumber;
    }
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.updateCallback({
        firstName: this.firstName,
        lastName: this.lastName,
        sex: this.sex,
        socialEmail: this.email,
        birthday: this.birthday as any,
        passport: this.passport,
        passportInternational: this.passportInternational,
        phoneNumber: this.phoneNumber,
      })
        .then(() => {
          this.tryingToUpdate = false;
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  get sexOptions() {
    return [
      { text: '---', value: null },
      ...getSexTypeList()
    ];
  }

  get userName() {
    return this.user?.socialEmail || this.user?.phoneNumber || (`${this.user?.firstName || ''} ${this.user?.lastName || ''}`);
  }

  goToUsers() {
    this.$router.push({ name: 'users' });
  }

  onUpdatePermission() {
    this.$modal.show(UpdateUserPermissions, {
      updateCallback: (result: UsersUpdatePermissionsRequest) => {
        return this.updatePermissions({ id: this.user!.id, params: result})
          .then(() => this.goToUsers());
      },
      permissionList: preparePermissionList(this.user!.permissionList!) || {}
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  get isCurrentUser() {
    return this.user!.id === this.currentUser.id;
  }

  get canEditPermissions() {
    return hasPermission(PERM_CAN_EDIT_PERMISSIONS, this.currentUser.permissionList);
  }
}
