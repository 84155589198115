import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueTelInput, VueTelInputResponse } from 'vue-tel-input';

@Component({
  components: { VueTelInput }
})
export default class BPhoneInputValidation extends Vue {
  @Prop()
  value?: string;

  innerValue = '';
  isPhoneValid = false;
  isPhoneInput = false;
  isBlur = false;
  required = false;

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    this.innerValue = this.value || '';
    this.getCountry();

    if ({}.hasOwnProperty.call(this.$attrs, 'required')) {
      this.required = this.$attrs['required'] === '' || !!this.$attrs['required'];
    }
  }

  onBlur() {
    this.isBlur = true;
  }

  phoneValidate(response: VueTelInputResponse) {
    this.isPhoneValid = response.isValid;
  }

  keyInput(response: VueTelInputResponse) {
    this.isPhoneInput = !!response;
  }

  get validClass() {
    if (!this.isBlur || (!this.required && !this.isPhoneInput)) {
      return '';
    }

    return this.isPhoneValid && this.isPhoneInput ? 'is-valid' : 'is-invalid';
  }

  getCountry() {
    return fetch('https://ip2c.org/s')
      .then((response) => response.text())
      .then((response) => {
        const result = (response || '').toString();
        if (!this.innerValue && result && result[0] === '1') {
          this.setCountry(result.substr(2, 2));
        }
      });
  }

  setCountry(country: string) {
    const vueTelInput: any = this.$refs.vueTelInput;
    if (vueTelInput) {
      vueTelInput.activeCountry = vueTelInput.findCountry(country);
    }
  }
}
