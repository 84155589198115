import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import { Action } from 'vuex-class';
import UserForm from '@/components/pages/users/user-form/userForm.vue';
import { UserType } from '@/types/api/user/user.type';
import { UserCard } from '@/store/modules/users/actions';
import { UsersUpdateRequest } from '@/types/request/users-request.types';
import FormBlock from '@/components/ui/form-block/form-block.vue';

const title = 'users.update';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    UserForm,
    FormBlock,
  },
})
export default class UserUpdate extends Vue {
  title = title;
  userId!: number;
  user: UserType | null = null;

  @Action('getUser', { namespace: 'users' })
  getUser!: (id: number) => Promise<UserType>;

  @Action('updateUser', { namespace: 'users' })
  updateUser!: (data: UserCard<UsersUpdateRequest>) => Promise<UserType>;

  @Action('deleteUser', { namespace: 'users' })
  deleteUser!: (id: number) => Promise<void>;

  created(): void {
    if (this.$route.params.id) {
      this.userId = Number(this.$route.params.id);
      this.getUser(this.userId)
        .then(result => {
          this.user = result;
          return result;
        })
        .catch(() => this.goToUsers());
    } else {
      this.goToUsers();
    }
  }

  goToUsers() {
    this.$router.push({ name: 'users' });
  }

  onApply(params: UsersUpdateRequest) {
    return this.updateUser({
      id: this.userId,
      params: params
    })
      .then(() => {
        this.goToUsers();
      });
  }

  onDeleteUser() {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteUser(this.user!.id)
              .then(() => this.goToUsers());
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }
}
