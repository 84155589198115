<template>
  <div class="ui-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t('users.updatePermissions') }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ValidationObserver v-slot="{ invalid, passes }">
        <b-form @submit.prevent="passes(onApply)">
          <div class="modal-body ui-modal-body">
            <b-form-checkbox
              :id="perm"
              v-for="perm of Object.keys(permissions)"
              v-model="permissions[perm]"
              :key="perm"
              :disabled="tryingToUpdate"
              switch
            >
              {{ getPermissionLabel(perm) }}
            </b-form-checkbox>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="cancel"
            >
              {{ $t('common.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary" :disabled="invalid">
              <b-spinner small v-if="tryingToUpdate"></b-spinner>
              {{ $t('common.save') }}
            </button>
          </div>
        </b-form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
  import isFunction from 'lodash/isFunction';
  import isEmpty from 'lodash/isEmpty';
  import { ValidationObserver } from 'vee-validate';
  import { getPermissionLabel, defaultPermissionsValues } from '@/helpers/permission.helper';

  export default {
    components: {
      ValidationObserver,
    },
    props: {
      updateCallback: {
        required: true
      },
      permissionList: {
        required: true
      }
    },
    data: function() {
      return {
        tryingToUpdate: false,
        permissions: defaultPermissionsValues,
      };
    },
    created: function () {
      if (!isEmpty(this.permissionList)) {
        this.permissions = { ...this.permissionList };
      }
    },
    methods: {
      onApply: function () {
        if (isFunction(this.updateCallback) && !this.tryingToUpdate) {
          this.tryingToUpdate = true;
          this.updateCallback(this.permissions)
            .then(() => {
              this.tryingToUpdate = false;
              this.cancel();
            })
            .catch(() => {
              this.tryingToUpdate = false;
            });
        }
      },
      cancel: function () {
        this.$emit('close');
      },
      onChoose: function (value) {
        this.venueId = value ? value.id : null;
      },
      getPermissionLabel: function (perm) {
        return getPermissionLabel(perm);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ui-modal {
    .ui-modal-body {
      max-height: 500px;
      overflow: auto;
    }
  }
</style>
